import request from '@/utils/request'

export function getUserByProjectID(id) {
    return request({
        url: '/users/local_user_id/' + id,
        method: 'get'
    })
}

export async function getUserByProjectIdAsync(id) {
    return await request({
        url: '/users/local_user_id/' + id,
        method: 'get'
    })
}

export function getUserByID(id) {
    return request({
        url: '/users/firebase_user_id/' + id,
        method: 'get'
    })
}

export function getTransaction(id) {
    return request({
        url: '/users/transaction/' + id,
        method: 'get'
    })
}