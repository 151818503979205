<template>
  <div class="app-container"  v-loading="loading">
    <div class="filter-container">
      <el-input
          v-model="queryInfo.user_id"
          placeholder="User ID"
          style="width: 400px;"
          class="filter-item"
          @keyup.enter.native="handleFilter"
      />
      <el-select
          v-model="queryInfo.scope"
          placeholder="Login Provider"
          clearable
          style="width: 200px"
          class="filter-item"
      >
        <el-option v-for="item in queryScopeOptions" :key="item" :label="item" :value="item" />
      </el-select>
      <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
      >Search</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" default-expand-all>
      <el-table-column type="expand">
        <template slot-scope="props">
          <p>Model: {{ props.row.device_model }}</p>
          <p>OS: {{ props.row.operating_system }}</p>
          <p>GPU: {{ props.row.graphics_device_name }}</p>
          <p>Device Name: {{ props.row.device_name }}</p>
          <p>Environment: {{ props.row.environment }}</p>
          <p>Join: {{ props.row.time_created }}</p>
          <p>Google Id: {{ props.row.google_id }}</p>
          <p>Apple Id: {{ props.row.apple_id }}</p>
        </template>
      </el-table-column>
      <el-table-column label="Model" prop="device_model"></el-table-column>
      <el-table-column label="Join Date" prop="time_created"></el-table-column>
      <el-table-column>
        <template slot-scope="props">
          <el-button
              size="mini"
              icon="el-icon-info"
              @click="handleUserInfo(props.$index, props.row)"
          >User Infomation</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getUserByProjectID, getUserByProjectIdAsync, getUserByID } from "@/api/users.js";
import convertDateString from "@/utils/get-date-string";
import requestServiceBase from "@/utils/request-service-base";
import router from "@/router";
import request from "@/utils/request-service-base";
import {Message} from "element-ui";

export default {
  name: "Users",
  components: {},
  data() {
    return {
      queryScopeOptions: ["Firebase ID", "Project ID", "User ID"],
      queryInfo: {
        scope: "User ID",
        user_id: undefined
      },
      tableData: [],
      loading: false,
    };
  },
  created() {},
  methods: {
    async getUserByUserId() {
      try {
        this.loading = true;

        let dataRequest = {
          UserId: this.queryInfo.user_id,
        };

        let responseLoginId = await request({
          url: `/maintain/user-profile/get-loginid-from-userid`,
          method: `post`,
          data: dataRequest
        });

        let loginId = responseLoginId.data.LoginId;

        let response = await getUserByProjectIdAsync(loginId);
        this.fetchData(response);

        this.loading = false;
      }
      catch (ex) {
        Message({
          message: `Error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });

        this.loading = false;
      }
    },

    handleFilter() {
      this.loading = true;
      this.tableData = [];
      if (this.queryInfo.scope === this.queryScopeOptions[0]) {
        getUserByID(this.queryInfo.user_id)
            .then(response => {
              this.loading = false;
              this.fetchData(response);
            })
            .catch(error => {
              this.loading = false;
            });
      }
      else if (this.queryInfo.scope === this.queryScopeOptions[1]) {
        getUserByProjectID(this.queryInfo.user_id)
            .then(response => {
              this.loading = false;
              console.log(response);
              this.fetchData(response);
            })
            .catch(error => {
              this.loading = false;
            });
      }
      else if (this.queryInfo.scope === this.queryScopeOptions[2]) {
        this.getUserByUserId();
      }
    },
    fetchData(element) {
      let t = new Date(1970, 0, 1);
      let google_id_temp = "";
      let apple_id_temp = "";
      if (element.google_id !== undefined) {
        google_id_temp = element.google_id;
      }

      if (element.apple_id !== undefined) {
        apple_id_temp = element.apple_id;
      }

      t.setSeconds(element.time_created._seconds);
      this.tableData.push({
        device_model: element.device_model,
        operating_system: element.operating_system,
        graphics_device_name: element.graphics_device_name,
        device_name: element.device_name,
        environment: element.environment,
        db_id: element.db_id,
        time_created: this.getDateString(t),
        google_id: google_id_temp,
        apple_id: apple_id_temp
      });
    },
    handleUserInfo(index, row) {
      router.push({ name: "User Infomation", params: { user_id: row.db_id } });
    },
    getDateString(d) {
      return convertDateString(d);
    }
  }
};
</script>